<template>
  <WsMain>
    <WsText>
      Page Not Found :(
    </WsText>
  </WsMain>
</template>

<script>
export default {};
</script>

<style>
</style>